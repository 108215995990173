<script setup lang="ts">
const {locale, setLocale, t} = useI18n()

const enLabel = computed(() => t('en'))
const deLabel = computed(() => t('de'))


const dropdownMode = computed(() => {
  return  'click'
});

onMounted(() => {
  if (locale.value === 'en-US' || locale.value === 'en-GB') {
    setLocale('en')
  } else if (locale.value === 'de-DE' || locale.value === 'de-CH' || locale.value === 'de-AT') {
    setLocale('de')
  }
})

const items = [
  [{
    label: enLabel,
    code: 'en',
    click: () => setLocale('en'),
  }, {
    label: deLabel,
    code: 'de',
    click: () => setLocale('de'),
  }]
]

function getLanguageIcon(code: string) {
  switch (code) {
    case 'en':
      return 'emojione:flag-for-united-states'
    case 'de':
      return 'emojione:flag-for-germany'
    default:
      return 'emojione:flag-for-united-states'
  }
}
</script>

<template>
  <UDropdown :items="items" :mode="dropdownMode" :popper="{ placement: 'bottom-start' }">
    <UButton class="custom-dropdown-button" color="white" trailing-icon="i-heroicons-chevron-down-20-solid">
      <Icon :name="getLanguageIcon(locale)" class="round-icon" style="margin-top: 2px"/>
      <span class="hidden sm:inline-block">{{ $t(locale) }}</span>
    </UButton>
    <template #item="{ item }">
      <Icon :name="getLanguageIcon(item.code)" class="round-icon"/>
      <span class="truncate">{{ item.label.value }}</span>
    </template>
  </UDropdown>
</template>

<style scoped>
.custom-dropdown-button {
  border: none !important; /* Removes the border */
  box-shadow: none !important; /* Removes any shadow if present */
}

.custom-dropdown-button:hover {
  border: none !important; /* Ensures border is removed on hover */
  box-shadow: none !important; /* Ensures shadow is removed on hover */
}

.round-icon {
  border-radius: 50%; /* Makes the icon round */
  box-shadow: 0 0 0 1px #bababa; /* Creates an outline around the icon */
  padding: 4px; /* Optional: Adds some padding inside the icon */
}
</style>
<script setup lang="ts">
import {useShare} from '@vueuse/core'
import LangSwitcher from "~/components/LangSwitcher.vue";
const {t} = useI18n()

const {share, isSupported} = useShare()

const shareTitle = computed(() => t('shareTitle'))
const shareText = computed(() => t('shareText'))

function startShare() {
  let url = new URL(location.href);
  url.searchParams.delete('emu');

  share({
    title: shareTitle.value,
    text: shareText.value,
    url: url.toString(),
  })
}


</script>

<template>
  <div class="sticky top-0 z-50 h-20 bg-white flex items-center justify-between p-5 border-b border-gray-300">
    <img src="/Explorial_Logo.svg" alt="Explorial" class="h-full"/>
    <div class="flex flex-grow justify-end px-2 sm:px-8">
      <LangSwitcher/>
    </div>
    <button v-if="isSupported" class="relative z-50" :disabled="!isSupported" @click="startShare">
      <UIcon class="h-7 w-7" name="i-heroicons-share-solid"/>
    </button>
  </div>
</template>

<style scoped>
</style>
